export const mainMenuItems = [
  {
    path: "/#meist",
    text: "meist",
  },
  {
    path: "/#teenused",
    text: "teenused",
  },
  {
    path: "/#kontakt",
    text: "kontakt",
  },
]

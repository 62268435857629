export const mainMenuItems = [
  {
    path: "/#meist",
    title: "meist",
  },
  {
    path: "/#teenused",
    title: "teenused",
  },
  {
    path: "/#kontakt",
    title: "kontakt",
  },
]

export const socialMenuItems = [
  // {
  //   icon: <FaLinkedin />,
  //   url: "https://www.linkedin.com/in/morgan-baker-developer-inverness",
  //   name: "LinkedIn",
  // },
  // {
  //   icon: <FaTwitter />,
  //   url: "https://www.twitter.com",
  //   name: "Twitter",
  // },
  // {
  //   icon: <DiGithubBadgatsby-config.jsge />,
  //   url: "https://github.com/bagseye",
  //   name: "GitHub",
  // },
]

export const footerMenuItems = [
  {
    path: "/privaatuspoliitika",
    title: "Privaatuspoliitika",
  },
]
